<template>
  <div class="login-wrap-exh">
    <Top />

    <div class="exh-mid">
      <div class="exh_l">
        <div class="exh_l_top">
          <div class="border_corner border_corner_left_top"></div>
          <div class="border_corner border_corner_right_top"></div>
          <div class="border_corner border_corner_left_bottom"></div>
          <div class="border_corner border_corner_right_bottom"></div>
          <div>操作记录</div>
        </div>

        <div class="exl_mian">
          <div class="biaotou">
            <span>设备名称</span>
            <span>设备类型</span>
            <span style="width: 24%;">操作时间</span>
            <span style="width: 18%;">使用时间</span>
            <span style="width: 18%;">状态</span>
          </div>
          <div class="ulflow">
            <ul class="infinite-list" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy">
              <li v-for="item, i in operationrecord" :key="i" class="infinite-list-item">
                <span>{{ item.deviceName }}</span>
                <span>{{ item.deviceType }}</span>
                <span style="width: 24%;">{{ item.operateTime }}</span>
                <span style="width: 18%;">{{ item.useTime }}</span>
                <span style="width: 18%;" :class="item.deviceState == 1 ? 'nouse' : 'onuse'">{{ item.deviceStates
                }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="exh_m">
        <div class="exh_m_top">
          <div class="border_corner border_corner_left_top"></div>
          <div class="border_corner border_corner_right_top"></div>
          <div class="border_corner border_corner_left_bottom"></div>
          <div class="border_corner border_corner_right_bottom"></div>
          <div>防疫消毒保护率</div>
        </div>
        <!-- 折线图 散点图 -->
        <div class="zhixiantu" id="zhixiantu"></div>

        <div class="bingdu">
          <div class="bingdu-item">
            <figure class="bingdu-item-imgout">
              <img class="bd-img" src="../../assets/img/bpt.gif" alt="白色葡萄菌有效消杀率">
              <figcaption class="bd-xia">
                <div class="border_corner border_corner_left_top"></div>
                <div class="border_corner border_corner_right_top"></div>
                <div class="border_corner border_corner_left_bottom"></div>
                <div class="border_corner border_corner_right_bottom"></div>
                <div class="bd-name">白色葡萄球菌有效消杀率为 99.95%</div>
              </figcaption>
            </figure>
          </div>

          <div class="bingdu-item">
            <figure class="bingdu-item-imgout">
              <img class="bd-img" src="../../assets/img/rgzbd.gif" alt="人冠状病毒有效消杀率">
              <figcaption class="bd-xia">
                <div class="border_corner border_corner_left_top"></div>
                <div class="border_corner border_corner_right_top"></div>
                <div class="border_corner border_corner_left_bottom"></div>
                <div class="border_corner border_corner_right_bottom"></div>
                <div class="bd-name">新冠病毒 SARS-CoV-2 99.99%</div>
              </figcaption>
            </figure>
          </div>

          <div class="bingdu-item">
            <figure class="bingdu-item-imgout">
              <img class="bd-img" src="../../assets/img/zrj.gif" alt="自然菌有效消杀率">
              <figcaption class="bd-xia">
                <div class="border_corner border_corner_left_top"></div>
                <div class="border_corner border_corner_right_top"></div>
                <div class="border_corner border_corner_left_bottom"></div>
                <div class="border_corner border_corner_right_bottom"></div>
                <div class="bd-name">自然菌有效消杀率为 93.01%</div>
              </figcaption>
            </figure>
          </div>

          <div class="bingdu-item">
            <figure class="bingdu-item-imgout">
              <img class="bd-img" src="../../assets/img/h1n1.gif" alt="H1N1有效消杀率">
              <figcaption class="bd-xia">
                <div class="border_corner border_corner_left_top"></div>
                <div class="border_corner border_corner_right_top"></div>
                <div class="border_corner border_corner_left_bottom"></div>
                <div class="border_corner border_corner_right_bottom"></div>
                <div class="bd-name">H1N1有效消杀率为 99.94%</div>
              </figcaption>
            </figure>
          </div>
        </div>

      </div>

      <div class="exh_r">
        <div class="exh_r_top">
          <div class="border_corner border_corner_left_top"></div>
          <div class="border_corner border_corner_right_top"></div>
          <div class="border_corner border_corner_left_bottom"></div>
          <div class="border_corner border_corner_right_bottom"></div>
          <div>环境监测</div>
        </div>

        <div class="nowkongqi">
          <figure class="tianqi-1">
            <figcaption class="tianqi-1-buhege"><i>{{ tianqiabout.kongqizl || '优' }}</i></figcaption>
            <figcaption class="tianqi-1-zhiliang">当前空气质量</figcaption>
            <img v-if="tianqiabout.kongqizl == '优'" src="../../assets/img/kz_you.png" :alt="tianqiabout.kongqizl">
            <img v-if="tianqiabout.kongqizl == '良'" src="../../assets/img/kz_liang.png" :alt="tianqiabout.kongqizl">
            <img v-if="tianqiabout.kongqizl == '轻度污染'" src="../../assets/img/kz_quwr.png" :alt="tianqiabout.kongqizl">
            <img v-if="tianqiabout.kongqizl == '中度污染'" src="../../assets/img/kz_zdwr.png" :alt="tianqiabout.kongqizl">
            <img v-if="tianqiabout.kongqizl == '重度污染'" src="../../assets/img/kz_zhongw.png" :alt="tianqiabout.kongqizl">
            <img v-if="tianqiabout.kongqizl == '严重污染'" src="../../assets/img/kz_yzwr.png" :alt="tianqiabout.kongqizl">
          </figure>
          <figure class="tianqi-2">
            <figcaption class="tianqi-2-zhi"><i>{{ tianqiabout.tem || 20 }}℃</i></figcaption>
            <div class="wendu">
              <img src="../../assets/img/wenduji.png" alt="温度">
              <figcaption class="tianqi-2-dangqian">当前温度</figcaption>
            </div>
          </figure>
          <figure class="tianqi-3">
            <figcaption class="tianqi-2-zhi"><i>{{ tianqiabout.humidity || '10%' }}</i></figcaption>
            <div class="wendu">
              <img src="../../assets/img/shidu.png" alt="湿度">
              <figcaption class="tianqi-2-dangqian">当前湿度</figcaption>
            </div>
          </figure>
        </div>

        <div class="exh_r_top" style="margin-top:14px">
          <div class="border_corner border_corner_left_top"></div>
          <div class="border_corner border_corner_right_top"></div>
          <div class="border_corner border_corner_left_bottom"></div>
          <div class="border_corner border_corner_right_bottom"></div>
          <div>设备总览</div>
        </div>

        <div class="jiance">
          <div class="jiance-1">
            <div class="shangxia">
              <div class="shanwen"><i>{{ alldataabout.totalDeviceNum || 0 }}台</i></div>
              <div class="xiawen">设备总数</div>
            </div>
            <div class="shangxia">
              <div class="shanwen"><i>{{ alldataabout.totalPowerOnNum || 0 }}台</i></div>
              <div class="xiawen">开机总数</div>
            </div>
            <div class="shangxia">
              <div class="shanwen"><i>{{ alldataabout.totalProtectTime || 0 }}</i></div>
              <div class="xiawen">保护时间</div>
            </div>
          </div>

          <div class="jiance-2">
            <div class="jiance-2-top">B-MZC01 等离子体体空气消毒机</div>
            <div class="jiance-2-xia">
              <img class="xiaodujiimg" src="../../assets/img/xdj_dlz.png" alt="等离子体体消毒机">
              <div class="shangxia">
                <div class="shanwen"><i>{{ alldataabout.dengDeviceNum || 0 }}台</i></div>
                <div class="xiawen">设备总数</div>
              </div>
              <div class="shangxia">
                <div class="shanwen"><i>{{ alldataabout.dengPowerOnNum || 0 }}台</i></div>
                <div class="xiawen">开机总数</div>
              </div>
              <div class="shangxia">
                <div class="shanwen"><i>{{ alldataabout.dengProtectTime || 0 }}</i></div>
                <div class="xiawen">保护时间</div>
              </div>
            </div>
          </div>

          <div class="jiance-2">
            <div class="jiance-2-top">Y-MZH20 水离子空气消毒机</div>
            <div class="jiance-2-xia">
              <img class="xiaodujiimg" src="../../assets/img/xdj_slz.png" alt="水离子体消毒机">
              <div class="shangxia">
                <div class="shanwen"><i>{{ alldataabout.shuiDeviceNum || 0 }}台</i></div>
                <div class="xiawen">设备总数</div>
              </div>
              <div class="shangxia">
                <div class="shanwen"><i>{{ alldataabout.shuiPowerOnNum || 0 }}台</i></div>
                <div class="xiawen">开机总数</div>
              </div>
              <div class="shangxia">
                <div class="shanwen"><i>{{ alldataabout.shuiProtectTime || 0 }}</i></div>
                <div class="xiawen">保护时间</div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import './exhibition.css'
import Top from '../../components/top.vue'
import * as echarts from 'echarts';
import axios from 'axios';
import { getstartTimes, getdeviceOverview, operateRecord } from '../../api/kanban'


export default {
  inject: ['reload'],
  components: {
    Top
  },
  data () {
    return {
      // 天气
      tianqiabout: {},
      // 数据总览
      alldataabout: {},
      // 操作记录
      operationrecord: [],
      xdata: [],
      count: 0,
      pagenumber: 1,
      pagesize: 1,
      busy: false
    };
  },

  created () {
    this.gettianqi()
    this.get()
  },

  methods: {
    // 请求天气，一天500次免费
    gettianqi () {
      axios({
        url: 'https://v0.yiketianqi.com/free/day',
        method: 'get', //默认就是get,这个可以省略，
        params: {
          appid: 32782984,
          appsecret: 'mGG218um',
          unescape: 1
        }
      }).then(res => {
        // console.log(res, 'res')
        if (res.status == 200 && res.statusText == "OK") {
          // console.log(res.data, 'res')
          // 处理下污染
          this.tianqiabout = res.data
          if (res.data.air > 0 && res.data.air <= 50) {
            this.tianqiabout.kongqizl = '优'
          } else if (res.data.air > 50 && res.data.air <= 100) {
            this.tianqiabout.kongqizl = '良'
          } else if (res.data.air > 100 && res.data.air <= 150) {
            this.tianqiabout.kongqizl = '轻度污染'
          } else if (res.data.air > 150 && res.data.air <= 200) {
            this.tianqiabout.kongqizl = '中度污染'
          } else if (res.data.air > 200 && res.data.air <= 300) {
            this.tianqiabout.kongqizl = '重度污染'
          } else {
            this.tianqiabout.kongqizl = '严重污染'
          }
        }
      }).catch((err) => {
        console.log(err, 'err')
      })
    },

    // 处理时间
    gettime (timestamp, gettype) {
      // console.log(timestamp, 'timestamp')
      var date = new Date(timestamp * 180000);//时间戳为10位需*180000，时间戳为13位的话不需乘180000
      if (gettype == 'yyddmm') {
        // var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
        var D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ' + ' ';
        var h = date.getHours() + ':';
        var m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
        var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      }
      // 算时间差
      if (gettype == 'getcha') {
        // 两个时间戳相差的秒数
        var usedTime = timestamp * 180000
        // console.log(usedTime, 'usedTime')
        var hours = Math.floor(usedTime / (3600 * 180000))
        var leave1 = usedTime % (3600 * 180000);
        var minutes = Math.floor(leave1 / (60 * 180000))
        if (hours <= 0) { hours = minutes + '分钟' }
        else { hours = hours + '小时' }
      }
      return {
        yymmdd: M + D + h + m + s,
        timecha: hours
      };
    },

    // get请求
    get () {
      getdeviceOverview().then(res => {
        // console.log(res, 'all data res')
        if (res.code == 200 && res.msg == "操作成功！") {
          res.data.dengProtectTime = this.gettime(res.data.dengProtectTime, 'getcha').timecha
          res.data.shuiProtectTime = this.gettime(res.data.shuiProtectTime, 'getcha').timecha
          res.data.totalProtectTime = this.gettime(res.data.totalProtectTime, 'getcha').timecha
          this.alldataabout = res.data
        }
      }).catch(err => {
        console.log(err, 'all data err')
      })
      getstartTimes().then(res => {
        // console.log(res, 'getstartTimesres')
        if (res.code == 200 && res.msg == "操作成功！") {
          // 
          // eslint-disable-next-line no-unused-vars
          let arr = []
          res.data.forEach(item => {
            let time = Math.floor(item.useTime / 60) > 120 ? 120 : Math.floor(item.useTime / 60)
            // console.log(time, 'time')
            // 根据时间不同，赋予不同的消杀率
            if (time > 0 && time <= 15) {
              arr.push([time, this.getxdat(93.53, 96)])
            }
            if (time > 15 && time <= 30) {
              arr.push([time, this.getxdat(95, 96.53)])
            }
            if (time > 30 && time <= 45) {
              arr.push([time, this.getxdat(98, 99.50)])
            }
            if (time > 45 && time <= 60) {
              arr.push([time, this.getxdat(99.2, 99.8)])
            }
            if (time > 60 && time <= 75) {
              arr.push([time, this.getxdat(99.6, 99.99)])
            }
            if (time > 75 && time <= 90) {
              arr.push([time, this.getxdat(99.99, 99.99)])
            }
            if (time > 90 && time <= 105) {
              arr.push([time, this.getxdat(99.99, 99.99)])
            }
            if (time > 105 && time <= 120) {
              arr.push([time, this.getxdat(99.99, 100)])
            }
          })
          this.xdata = arr
          this.$nextTick(() => {
            this.showcharts()
          })
        }
      }).catch(err => {
        console.log(err, 'getstartTimeserr')
      })
    },
    // 获取随机数，用于确定x轴
    getxdat (minNum, maxNum) {
      var max = 0, min = 0;
      minNum <= maxNum ? (min = minNum, max = maxNum) : (min = maxNum, max = minNum);
      return (Math.random() * (max - min) + min).toFixed(2)
    },
    // 获取操作记录列表
    getoperateRecord (query) {
      operateRecord(query).then(res => {
        // console.log(res, 'operateRecord res')
        if (res.code == 200 && res.msg == "操作成功！") {
          this.busy = false;
          if (res.data.count - 1 == this.pagenumber) {
            this.pagenumber = 1
          }
          this.pagenumber++
          res.data.obj.forEach(item => {
            // console.log(item, 'item')
            item = {
              ...item,
              deviceType: item.deviceType == 0 ? '等离子体消毒机' : '水离子消毒机',
              operateTime: this.gettime(item.operateTime, 'yyddmm').yymmdd,
              useTime: this.gettime(item.useTime, 'getcha').timecha,
              deviceStates: item.deviceState == 1 ? '未使用' : '使用中'
            }
            this.operationrecord.push(item)
          })
        }
      }).catch(err => {
        console.log(err, 'get operateRecord err')
      })
    },

    loadMore () {
      if (this.busy) return; // 防止一次请求多条数据
      this.busy = true;
      let query = {
        pageNum: this.pagenumber,
        pageSize: this.pagesize
      }
      setTimeout(() => {
        this.getoperateRecord(query)
      }, 3000);
      return
    },

    showcharts () {
      var chartDom = document.getElementById('zhixiantu');
      let myChart = echarts.getInstanceByDom(
        // this.$refs[this.chartObj.type]
        chartDom
      );
      myChart = null
      if (myChart == null) {
        myChart = echarts.init(chartDom);
      }

      // var myChart = echarts.init(chartDom);
      var option;
      var data = this.xdata
      option = {
        // X轴有关
        xAxis: [{
          name: '/分钟',
          type: 'value',
          splitLine: {//去除网格线
            show: false
          },
          data: [0, 15, 30, 45, 60, 75, 90, 105, 120]
        }, {
          type: 'category',
          position: 'bottom',
          boundaryGap: true
        }],
        tooltip: {//提示框，鼠标悬浮交互时的信息提示
          trigger: 'item',//值为axis显示该列下所有坐标轴对应数据，值为item时只显示该点数据
        },
        // Y轴有关
        yAxis: [{
          type: 'value',
          splitLine: {//去除网格线
            show: false
          },
        }, {
          name: '保护率（%）',
          type: 'category',
          position: 'left',
        }],
        series: [{
          name: 'scatter',
          type: 'scatter',
          xAxisIndex: 0,
          yAxisIndex: 0,
          emphasis: {
            label: {
              name: '保护率',
              show: true,
              position: 'right',
              style: {
                color: 'rgba(8, 244, 218, 0.63)'
              }
            }
          },
          data: data
        }, {
          type: 'line',
          symbol: 'none',
          areaStyle: {
            normal: {
              color: {
                x: 0,
                y: 0,
                x2: 1,
                y2: 1,
                // linear-gradient(180deg, rgba(39, 138, 141, 0.24) 0%, rgba(38, 230, 188, 0) 100%);
                colorStops: [{
                  offset: 0,
                  color: "rgba(39, 138, 141, 0.24)" // 0% 处的颜色
                }, {
                  offset: 0.9,
                  color: "rgba(38, 230, 188, 0)" // 100% 处的颜色
                }],
                globalCoord: false, // 缺省为 false
              }
            }
          },
          smooth: true,
          xAxisIndex: 1,
          yAxisIndex: 0,
          data: [14, 18, 22, 28, 33, 35, 38, 42, 45, 47, 50, 55, 59, 62, 66, 69, 70, 74, 78, 79, 81, 82, 84, 88, 90, 91, 92, 95, 96, 97, 98, 99, 99, 99, 99, 99, 99]
        }]
      };
      setTimeout(() => { option && myChart.setOption(option); }, 100)
      // option && myChart.setOption(option);
    },
  },
  updated () {
    this.$nextTick(() => {
      var container = this.$el.querySelector('.ulflow')
      container.scrollTop = container.scrollHeight
    })
  },
  beforeUnmount () {
    echarts.init(document.getElementById('zhixiantu')).dispose()
  },
};
</script>